import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthObserver } from '@core/hooks'
import { useStore } from '@core/contexts'

import { Switch, Route, Redirect } from 'react-router-dom'
import { Layout } from '@components/templates'

import moment from 'moment'
import routes from '@configs/routes'
import { FORMAT, PATH, ISO_LANGUAGE_CODE } from '@configs/constants'
import { Icon } from 'rsuite'
import { Icon as NewIcon } from '@rsuite/icons'
import LanguageIcon from '@assets/image/svg/languageIcon'

const AUTH_PATH_NAMES = useAuthObserver.AUTH_PATH_NAMES

const App = () => {
  const { signedIn, user, pathname, history } = useAuthObserver()
  const { t, i18n } = useTranslation()
  const [, { setLanguage }] = useStore()

  const menu = React.useMemo(
    () =>
      user && user.userRole === 'admin'
        ? [
            {
              label: t('menu.search'),
              key: PATH.SEARCH,
              icon: <Icon icon="search" />,
              link: '/search'
            },
            {
              label: t('menu.Language-maintenance'),
              key: PATH.LANGUAGE,
              icon: <NewIcon as={LanguageIcon} />,
              link: '/languages'
            },
            {
              label: 'Dashboard',
              key: PATH.DASHBOARD,
              icon: <Icon icon="dashboard" />,
              link: '/dashboard'
            }
          ]
        : [
            {
              label: t('menu.search'),
              key: PATH.SEARCH,
              icon: <Icon icon="search" />,
              link: '/search'
            },
            {
              label: 'Dashboard',
              key: PATH.DASHBOARD,
              icon: <Icon icon="dashboard" />,
              link: '/dashboard'
            }
          ],
    [t, user]
  )

  const onSelect = (eventKey) => {
    if (AUTH_PATH_NAMES.includes(eventKey) && signedIn) history.push(eventKey)
  }

  // observer for check changing language
  React.useEffect(() => {
    const lang = i18n.language
    try {
      require('moment/locale/' + ISO_LANGUAGE_CODE[lang])
    } catch (error) {
      console.log('i18n', error?.message)
    } finally {
      setLanguage(lang)
    }

    return () => {
      window.location.reload(false)
    }
  }, [i18n.language])

  return (
    <Layout
      menu={menu}
      onSelect={onSelect}
      activeKey={pathname}
      signedIn={signedIn}
      {...(signedIn && {
        username: (() => {
          const names = [user.firstName, user.lastName]

          const hasValue = names.some((string) => !!string)
          if (hasValue) return names.join(' ')
        })(),
        lastLogin: moment(user.lastLoginDate, FORMAT.BE2FE)
      })}
    >
      <Switch>
        {routes.map(({ redirect, component: Component, ...route }, index) => {
          const key = route.path || index

          return (
            <Route key={key} exact {...route}>
              {redirect ? (
                <Redirect key={key} to={redirect} />
              ) : (
                <Component user={user} />
              )}
            </Route>
          )
        })}
      </Switch>
    </Layout>
  )
}

export default App
