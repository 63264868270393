import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '@core/contexts'

import { PATH, ROLE } from '@configs/constants'

const AUTH_PATH_NAMES = [PATH.SEARCH, PATH.DASHBOARD]

const useAuthObserver = () => {
  const [{ user }] = useStore()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const isTDEMRole = user?.branch === ROLE.TDEM
  const isAdminRole = user?.userRole === ROLE.ADMIN

  React.useEffect(() => {
    if (!user) {
      if (AUTH_PATH_NAMES.includes(pathname)) history.push(PATH.HOME)
    } else if (pathname === PATH.HOME) {
      const queryParams = new URLSearchParams(search)
      const auth = queryParams.get('auth')

      if (auth) return

      history.push(PATH.SEARCH)
    }
  }, [user, pathname, history])

  return {
    pathname,
    history,
    user,
    signedIn: !!user,
    isTDEMRole,
    isAdminRole
  }
}

useAuthObserver.AUTH_PATH_NAMES = AUTH_PATH_NAMES

export default useAuthObserver
